import React, {useEffect} from "react";
import Cookies from 'js-cookie';

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import Home from './home';
import Markets from './management-page';
import Products from './products-page';
import Resorces from './document-page';
import SingleNews from './news-single-page';
import Vehicles from './vehicles-page';
import Contact from './contact-page';
import Basic from './basic-page';
import Faq from './faq-page';
import {navigate} from "gatsby";
import OneTrust from "../components/OneTrust";
import LangSwitcher from "../components/LangSwitcher";

export default ({ pageContext: { node, title, translations, languages, langCode, urlPath, menus } }) => {
  const meta = [];
  {node.metatag_arr !== undefined &&
      Object.entries(node.metatag_arr).forEach(([key, value]) => {
       meta.push({name: key, content: value});
  })}

  useEffect(() => {
    if (!translations.includes(langCode)) { // @temp
      navigate(urlPath);
    }
    fetch('https://get.geojs.io/v1/ip/country.json')
      .then((response) => response.json())
      .then(onCountryDetection)
      .catch(console.log);
  }, [null])

  const onCountryDetection = (data) => {
    const cookieName = 'lang-redirect';
    if(!(Cookies.get(cookieName))) {
      const mapCountryToLang = {
        'germany': 'de',
        'france': 'fr',
        'spain': 'es',
      };
      const { name } = data;
      const countryLang = mapCountryToLang[name.toLowerCase()] ?? 'en';
      if (countryLang !== langCode) {
        const prefix = (countryLang === 'en') ? '' : '/' + countryLang;
        const path = (prefix && urlPath === '/') ? '' : urlPath;
        navigate(prefix + path);
      }
      Cookies.set(cookieName, '1');
    }
  }

  return (
    <Layout languages={languages} langCode={langCode} urlPath={urlPath} menus={menus}>
      <OneTrust/>
      <SEO title={title} meta={meta}/>
      { // @temp
        (translations.length > 1) && (
          <div className="lang-switcher-banner">
            <LangSwitcher translations={translations} langCode={langCode} urlPath={urlPath} languages={languages}/>
          </div>
        )
      }
      { node.type[0].target_id === 'homepage' && <Home node={node} /> }
      { node.type[0].target_id === 'markets' && <Markets node={node} /> }
      { node.type[0].target_id === 'products' && <Products node={node} /> }
      { node.type[0].target_id === 'resources' && <Resorces node={node} /> }
      { node.type[0].target_id === 'news' && <SingleNews node={node} /> }
      { node.type[0].target_id === 'vehicles' && <Vehicles node={node} /> }
      { node.type[0].target_id === 'contact' && <Contact node={node} langCode={langCode} /> }
      { node.type[0].target_id === 'basic_page' && <Basic node={node} /> }
      { node.type[0].target_id === 'faq' && <Faq node={node} /> }
    </Layout>
  );
};
